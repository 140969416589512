export interface ViewProductGTMEvent {
    id: string;
    sku: string;
    name: string;
    currencyCode: string;
    price: number;
    brand: string;
    category: string;
    variant: string;
    size: string | unknown;
    item_id: string;
    item_variant: string;
}

export default function viewProductGTMEvent(event: ViewProductGTMEvent) {
    if ((window as any)?.dataLayer === undefined) {
        return;
    }
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
        event: 'gtm.productPageView',
        ecommerce: {
            currencyCode: event.currencyCode,
            add: {
                products: [event],
            },
        },
    });
}
