import { gql } from 'graphql-request';
import { CART_FIELDS } from '@app/lib/shopify/queries/fragments/cart-fields';

export const cartCreate = gql`
    mutation cartCreate($country: CountryCode, $input: CartInput!)
    @inContext(country: $country) {
        cartCreate(input: $input) {
            cart {
                ...cartFields
            }
            userErrors {
                message
                field
            }
        }
    }
    ${CART_FIELDS}
`;
