import { Product } from 'shopify-storefront-api-typings';
import fetchProduct from '@app/lib/shopify/methods/fetch-product';
import { ShopifyClientContext } from '@app/lib/shopify/client';

async function fetchProductsByHandles(
    clientSettings: ShopifyClientContext,
    handles: string[]
): Promise<Product[]> {
    const productPromise = (handle: string): Promise<Product> => {
        return new Promise(async (resolve, reject) => {
            const product = await fetchProduct(clientSettings, handle);
            if (!product) {
                reject([]);
                return null;
            }
            resolve(product);
        });
    };

    return await Promise.all(
        handles.map((handle: string) => productPromise(handle))
    );
}

export default fetchProductsByHandles;
