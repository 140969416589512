import { Context, Language } from './Context';
import { getContext } from './get-context';

export default function getOptionForLang(
    option: string,
    lang: Language
): string | null {
    const context: Context = getContext(lang);

    if (!context) throw new Error(`No context found for lang ${lang}`);

    return context[option];
}
