import { gql } from 'graphql-request';

const productCountByMetafield = gql`
    query ProductCountByMetafield(
        $collectionHandle: String!
        $filters: [ProductFilter!]
    ) {
        collection(handle: $collectionHandle) {
            handle
            products(first: 50, filters: $filters) {
                edges {
                    node {
                        id
                    }
                }
            }
        }
    }
`;

export default productCountByMetafield;
