declare const window: {
    _learnq: any;
} & Window;

export interface AddToCartKlaviyoEvent {
    productURL: string;
    imageUrl: string;
    productId: string;
    variantSku: string;
    productPrice: number;
    productCompareAtPrice: any;
    productCategory: string;
    productTitle: string;
    quantity: number;
    checkoutUrl: string;
}

export default function addToCartKlaviyoEvent(
    event: AddToCartKlaviyoEvent,
    debug: boolean = false
) {
    if (window._learnq === undefined) {
        if (debug)
            console.log('Failed to register Klaviyo: _learnq unavailable.');
        return;
    }

    if (debug) console.log('Processing Klaviyo add to cart event');

    // Comment this out for quick local testing
    if (
        typeof window._learnq?.isIdentified === 'undefined' ||
        !window._learnq?.isIdentified()
    ) {
        if (debug)
            console.log(`Can't fire Klaviyo event: session not identified`);
        return;
    }

    const KlaviyoItem = {
        $value: event.productPrice,
        Name: event.productTitle,
        ID: event.productId,
        SKU: event.variantSku,
        Categories: [event.productCategory],
        ImageURL: event.imageUrl,
        URL: event.productURL,
        Brand: 'MAAP',
        Price: event.productPrice,
        CompareAtPrice: event.productCompareAtPrice,
        Quantity: event.quantity,
        CheckoutUrl: event.checkoutUrl,
    };

    if (debug) console.log('_learnq Item', KlaviyoItem);

    window._learnq.push(['track', 'Added to Cart', KlaviyoItem]);
}
