'use client';

import React, { Suspense, useEffect, useState } from 'react';
import Linker from '@app/components/linker/linker';
import {
    CartButton,
    CartButtonProps,
    DownVector,
    NostoProductCardAttributes,
    ProductCardProduct,
    ProductCardVariant,
} from 'mmds';
import { convertPriceToNumber } from 'mmds/src/utilities/convert-price-to-number';
import { Language } from '@app/lib/store-context/Context';
import TruncateTitle from '@app/lib/helpers/truncate-title';
import { VariantLifecycleStatus } from '@app/[lang]/products/utilities/get-product-lifecycle-status';
import useLoopReturns from '@app/lib/loop-returns/state';
import { useSimplifiedBuyContext } from '@app/[lang]/products/context-simplified';
import s from './product-rec-card.module.css';
import cn from 'clsx';
import gtmTracker from 'mmds/src/components/product-card/trackers/gtm';
import nostoTracker from 'mmds/src/components/product-card/trackers/nosto';

type ProductRecCardProps = {
    product: ProductCardProduct;
    lang: Language;
    nostoProductCardAttributes?: NostoProductCardAttributes;
    onClick?: () => void;
    isCartPage: boolean;
};

const ProductRecCard = ({
    product,
    lang,
    nostoProductCardAttributes,
    onClick,
    isCartPage,
}: ProductRecCardProps) => {
    const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
    const { isCreditShoppingActive } = useLoopReturns((state) => ({
        isCreditShoppingActive: state.isCreditShoppingActive,
    }));
    const backgroundColor = isCartPage ? '#F7F7F7' : '#FFFFFF';
    const {
        selectedSize,
        selectedVariant,
        isVariantInCheckout,
        variantLifecycleStatus,
        callbacks,
    } = useSimplifiedBuyContext();

    const {
        id,
        sku,
        handle,
        title,
        price,
        compareAtPrice,
        currencyCode,
        image,
        variants,
        position,
    } = product;

    const handleChange = (event) => {
        callbacks.selectSize(event.target.value);
        setIsSelectOpen(false);
    };

    // If a product only has one size, autoselect it on mount
    useEffect(() => {
        if (variants.length === 1) {
            callbacks.selectSize(variants[0].size);
        }
    }, [variants, callbacks]);

    let priceAsNumber =
        typeof price !== 'number' ? convertPriceToNumber(price) : price;
    let compareAtPriceAsNumber =
        typeof compareAtPrice !== 'number'
            ? convertPriceToNumber(compareAtPrice)
            : compareAtPrice;

    const showCompareAtPrice =
        compareAtPrice && compareAtPriceAsNumber > priceAsNumber;

    const eventDetails = {
        id,
        sku,
        productTitle: title,
        variantTitle: title,
        category: 'Apparel',
        priceAsNumber,
        currency: currencyCode,
        position,
        handle,
    };

    const handleOnClick = () => {
        gtmTracker(eventDetails);
        if (id && nostoProductCardAttributes?.isNostoProductCard === true) {
            nostoTracker(id, nostoProductCardAttributes.result_id);
        }
        if (onClick) onClick();
    };

    const priceFormatter = new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: currencyCode,
    });

    const imageDimensions = { width: '80', height: '107' };
    const imageDimensionsString = new URLSearchParams(
        imageDimensions
    ).toString();

    const button: CartButtonProps = {
        label: 'Add to Bag',
        state: 'default',
        onClick: () => {},
    };

    if (selectedVariant) {
        if (variantLifecycleStatus === VariantLifecycleStatus.ACTIVE) {
            button.label = 'Add to Bag';
            button.state = 'selected';
            button.disabled = false;
            button.onClick = callbacks.addToCart;
        }

        if (variantLifecycleStatus === VariantLifecycleStatus.SOLD_OUT) {
            button.label = 'Sold out';
            button.state = 'soldOut';
            button.disabled = true;
            button.onClick = () => {};
        }
    }

    if (isVariantInCheckout) {
        button.label = 'Added';
        button.state = 'added';
        if (isCreditShoppingActive) {
            button.disabled = true;
        }
    }

    return (
        <Suspense fallback={<div>Loading product recommendation card...</div>}>
            <div
                className={s.root}
                onClick={handleOnClick}
                style={{ backgroundColor: backgroundColor }}
            >
                <div className={s.image}>
                    <Linker
                        passthrough={!handle}
                        href={`/products/${handle}`}
                        title={`Go to product ${title}`}
                        lang={lang}
                    >
                        <img
                            src={`${image}&width=240&height=321&crop=top`}
                            alt={`Product Image for ${title}`}
                            width={imageDimensions.width}
                            height={imageDimensions.height}
                            loading="lazy"
                        />
                    </Linker>
                </div>
                <div className={s.detail}>
                    <div className={cn(s.title, 'mmds-component-one-detail')}>
                        <TruncateTitle text={title} maxLength={20} />
                    </div>
                    {!!price && (
                        <div
                            className={cn(
                                s.priceContainer,
                                'mmds-component-one-detail'
                            )}
                        >
                            <div className={s.price}>
                                {priceFormatter.format(priceAsNumber)}{' '}
                                {currencyCode}
                            </div>
                            {showCompareAtPrice && (
                                <div className={s.compareAtPrice}>
                                    {priceFormatter.format(
                                        compareAtPriceAsNumber
                                    )}{' '}
                                    {currencyCode}
                                </div>
                            )}
                        </div>
                    )}
                    <div className={cn(s.colour, 'mmds-component-one-detail')}>
                        {variants[0].color}
                    </div>
                    <div className={s.sizeSelector}>
                        <span
                            className={cn(
                                s.sizeLabel,
                                'mmds-component-one-detail'
                            )}
                        >
                            Size :
                        </span>
                        <span>
                            <div
                                className={cn(
                                    s.variant,
                                    'mmds-component-one-detail-link'
                                )}
                            >
                                <>
                                    <select
                                        id="size"
                                        name="size"
                                        className={cn(s.variantSelect, {
                                            [s.oneSize]: variants.length === 1,
                                        })}
                                        value={selectedSize}
                                        onClick={() =>
                                            setIsSelectOpen(!isSelectOpen)
                                        }
                                        onChange={handleChange}
                                    >
                                        {variants?.map(
                                            (
                                                variant: ProductCardVariant,
                                                index
                                            ) => {
                                                const { size, availability } =
                                                    variant;

                                                return (
                                                    <option
                                                        value={size}
                                                        key={`${size}-${index}`}
                                                        disabled={
                                                            availability === 0
                                                        }
                                                    >
                                                        {size}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </select>
                                    {variants.length !== 1 && (
                                        <DownVector
                                            className={cn(s.variantVector, {
                                                [s.flip]: isSelectOpen,
                                            })}
                                        />
                                    )}
                                </>
                            </div>
                        </span>
                    </div>
                    <CartButton variant="upsell" {...button} />
                </div>
            </div>
        </Suspense>
    );
};

export default ProductRecCard;
