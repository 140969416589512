import { Language } from '@app/lib/store-context/Context';
import deleteCartId from './delete-cart-id';

export default (language: Language, value: string) => {
    if (typeof document === 'undefined') return null;
    if (!value) {
        deleteCartId(language);
        return null;
    }
    const EXPIRATION_DAYS = 28;
    const expires = `; expires=${new Date(
        Date.now() + EXPIRATION_DAYS * 24 * 60 * 60 * 1000 // That's a lot of seconds.
    ).toUTCString()}`;
    const cookieIdentifier = `shopify_cart_id_${language.toLowerCase()}`;
    document.cookie =
        cookieIdentifier + '=' + (value || '') + expires + '; path=/';
};
