import { Product, ProductVariant } from 'shopify-storefront-api-typings';

export enum VariantLifecycleStatus {
    ACTIVE = 'active',
    NOTIFY = 'notify',
    SOLD_OUT = 'sold_out',
    DISCONTINUED = 'discontinued',
    COMING_SOON = 'coming_soon',
}

function getVariantLifecycleStatus(
    product: Product,
    variant: ProductVariant
): VariantLifecycleStatus {
    if (variant.availableForSale) {
        return VariantLifecycleStatus.ACTIVE;
    }

    const comingSoon = product.tags.includes('COMINGSOON');
    if (comingSoon) {
        return VariantLifecycleStatus.COMING_SOON;
    }

    const disableNotify =
        product.tags.includes('disablenotifyme') ||
        product.tags.includes('DISABLENOTIFYME');
    if (disableNotify) {
        return VariantLifecycleStatus.SOLD_OUT;
    } else {
        return VariantLifecycleStatus.NOTIFY;
    }
}

export default getVariantLifecycleStatus;
