import { Product } from 'shopify-storefront-api-typings';
import { getClient, productByHandle } from '@app/lib/shopify';
import { ShopifyClientContext } from '@app/lib/shopify/client';
import { captureMessage } from '@sentry/nextjs';

export default async (
    context: ShopifyClientContext,
    handle: string
): Promise<Product | null> => {
    try {
        const response = await getClient(context).request.send({
            query: productByHandle,
            variables: {
                country: context.shopifyMarketCountryCode,
                handle,
            },
        });
        if (!response?.product) return null;
        return response?.product;
    } catch (e) {
        const message = `Could not fetch Shopify product for "${handle}"`;
        captureMessage(message);
        console.warn(message, e);
    }
    return null;
};
