'use client';

import React, { useEffect, useState } from 'react';

import {
    Product,
    ProductVariant,
    ProductVariantEdge,
} from 'shopify-storefront-api-typings';
import { useParams, usePathname } from 'next/navigation';
import useCart from '@app/[lang]/cart/state';
import getCartId from '@app/lib/shopify/checkout/get-cart-id';
import setCartId from '@app/lib/shopify/checkout/set-cart-id';
import addToCart from '@app/lib/shopify/methods/add-to-cart';
import { Language } from '@app/lib/store-context/Context';
import { getShopifyClientSettings } from '@app/lib/shopify/get-client-settings';
import useUI from '@app/components/ui/state';
import getVariantLifecycleStatus, {
    VariantLifecycleStatus,
} from '@app/[lang]/products/utilities/get-product-lifecycle-status';
import addToCartGTMEvent from '@app/lib/events/add-to-cart.gtm';
import addToCartPixelEvent from '@app/lib/events/add-to-cart.pixel';
import addToCartKlaviyoEvent from '@app/lib/events/add-to-cart.klaviyo';
import viewProductGTMEvent from '@app/lib/events/view-product.gtm';

interface SimplifiedBuyContext {
    selectedVariant: ProductVariant | undefined;
    selectedSize: string | undefined;
    isVariantInCheckout: boolean;
    variantLifecycleStatus: VariantLifecycleStatus | null;
    callbacks: {
        selectSize: (size: string) => void;
        addToCart: () => void;
    };
}

export const SimplifiedBuyContext =
    React.createContext<SimplifiedBuyContext | null>(null);

interface SimplifiedBuyContextProviderProps {
    children: React.ReactNode;
    product: Product;
    inDrawer?: boolean | undefined;
}

export const SimplifiedBuyContextProvider: React.FC<
    SimplifiedBuyContextProviderProps
> = ({ children, product, inDrawer }) => {
    const pathname = usePathname();
    const { lang } = useParams();
    const { setCart, cart } = useCart();
    const { toggleUI } = useUI();

    const defaultInStockVariant = (variants) => {
        const defaultVariant = variants.edges.find(
            (variant) => variant.node.quantityAvailable > 0
        );
        let defaultInStockVariant = defaultVariant;
        if (!defaultInStockVariant && variants.length > 0) {
            defaultInStockVariant = variants[0].node;
        }
        return defaultInStockVariant?.node;
    };
    const [selectedVariant, setSelectedVariant] = useState<
        ProductVariant | undefined
    >(defaultInStockVariant(product.variants));

    const clientSettings = getShopifyClientSettings(lang as Language);

    useEffect(() => {
        if (!selectedVariant) return;
        const viewProductEventDetails = {
            currencyCode: selectedVariant.priceV2.currencyCode,
            name: product.title,
            sku: selectedVariant.sku as string,
            id: product.id,
            price: selectedVariant.priceV2.amount,
            brand: 'MAAP',
            category: 'Apparel & Accessories',
            variant: selectedVariant.title,
            size: selectedVariant.selectedOptions.find(
                (option) => option.name === 'Size'
            )?.value,
            item_id: product.handle,
            item_variant: selectedVariant.sku as string,
        };
        viewProductGTMEvent(viewProductEventDetails);
    }, [selectedVariant]);

    const onSelectSize = (size: string) => {
        const variantEdge: ProductVariantEdge | undefined =
            product.variants.edges.find((edge: ProductVariantEdge) => {
                let sizeOption = edge.node.selectedOptions.find(
                    (option) => option.name === 'Size'
                );
                if (!sizeOption)
                    sizeOption = { name: 'Size', value: 'One size' };
                return sizeOption?.value === size;
            });
        if (!variantEdge) {
            console.log('No variant found for size', size);
            return;
        }
        setSelectedVariant(variantEdge.node);
    };

    const onAddToCart = async () => {
        if (selectedVariant) {
            const storedCartId = getCartId(lang as Language);

            const cart = await addToCart(
                clientSettings,
                storedCartId,
                selectedVariant
            );

            if (!cart) {
                console.log('No cart created', cart);
                return;
            }
            if (!storedCartId) {
                setCartId(lang as Language, cart.id);
            }

            if (cart) {
                setCart(cart);
                if (!inDrawer) toggleUI('cart');
                addToCartGTMEvent({
                    currencyCode: selectedVariant.priceV2.currencyCode,
                    name: product.title,
                    id: product.id,
                    price: selectedVariant.priceV2.amount,
                    brand: 'MAAP',
                    category: 'Apparel & Accessories',
                    quantity: 1,
                    variant: selectedVariant.title,
                    item_id: product.handle,
                    item_variant: selectedVariant.sku as string,
                });
                addToCartPixelEvent({
                    content_name: product.title,
                    content_ids: [product.id],
                    content_category: 'Apparel & Accessories',
                    content_type: 'product',
                    value: selectedVariant.priceV2.amount,
                    currency: selectedVariant.priceV2.currencyCode,
                    quantity: 1,
                });
                addToCartKlaviyoEvent(
                    {
                        productPrice: selectedVariant.priceV2.amount,
                        productTitle: product.title,
                        productId: product.id,
                        variantSku: selectedVariant.sku || '',
                        productCategory: 'Apparel & Accessories',
                        imageUrl: selectedVariant?.image?.transformedSrc,
                        productURL: pathname,
                        productCompareAtPrice: selectedVariant.compareAtPriceV2,
                        quantity: 1,
                        checkoutUrl: cart.checkoutUrl,
                    },
                    false
                );
            }
        }
    };

    const selectedSize =
        selectedVariant?.selectedOptions?.find(
            (option) => option.name === 'Size'
        )?.value || undefined;

    let isVariantInCheckout = false;
    if (cart && selectedVariant) {
        const itemInCheckout = cart.lines.edges.find(
            (edge) => edge.node?.merchandise?.id === selectedVariant.id
        );
        isVariantInCheckout = !!itemInCheckout;
    }

    let variantLifecycleStatus: null | VariantLifecycleStatus = null;
    if (selectedVariant) {
        variantLifecycleStatus = getVariantLifecycleStatus(
            product,
            selectedVariant
        );
    }

    const provides = {
        selectedVariant,
        selectedSize,
        isVariantInCheckout,
        variantLifecycleStatus,
        callbacks: {
            selectSize: onSelectSize,
            addToCart: onAddToCart,
        },
    };

    return (
        <SimplifiedBuyContext.Provider value={{ ...provides }}>
            {children}
        </SimplifiedBuyContext.Provider>
    );
};

export const useSimplifiedBuyContext = () => {
    const context = React.useContext(SimplifiedBuyContext);
    if (!context) {
        throw new Error(
            'useSimplifiedBuyContext must be used within a SimplifiedBuyContext'
        );
    }
    return context;
};
