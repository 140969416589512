'use client';

import React, { FC, useCallback, useLayoutEffect, useState } from 'react';
import {
    NostoProductCardAttributes,
    ProductCardProduct,
    ProductCardProps,
    ProductCardVariant,
} from 'mmds';
import findProductCardImagesInSet from '@app/lib/shopify/helpers/find-product-card-images-in-set';
import { useParams } from 'next/navigation';
import { Language } from '@app/lib/store-context/Context';
import Linker from '@app/components/linker/linker';
import SwiperProductNostoFlatlayVariant from '@app/components/swiper/SwiperProductNostoFlatlay.variant';
import SwiperProductNostoStandardVariant from '@app/components/swiper/SwiperProductNostoStandard.variant';
import SwiperProductNostoFlatlayCardAltVariant from '@app/components/swiper/SwiperProductNostoFlatlayCardAlt.variant';
import SwiperNostoUpsellCrossSellProductVariant from '@app/components/swiper/SwiperNostoUpsellCrossSellProduct.variant';

type Props = {
    placementIdentifier: any;
    type: 'product' | 'collection' | 'landing_page' | 'stories';
    title?: string;
    pageKey?: string;
    id?: string;
    shouldApplyPaddingBottom?: boolean;
    customRootClass?: string;
    swiperType?: 'product_card' | 'product_rec_card' | 'flatlay' | 'flatlay_alt';
    isCartPage?: boolean;
    isEmptyCartPage?: boolean;
};

type NostoProductProductResults = {
    products: any[];
    result_id: string;
    title?: string;
};

export const getSiblings = (customFields) => {
    let siblings = [];
    try {
        if (
            typeof customFields['related_products-siblings'] === 'undefined' ||
            !customFields['related_products-siblings']
        ) {
            return siblings;
        }
        siblings = JSON.parse(customFields['related_products-siblings']);
    } catch (error) {
        console.error('Error parsing related_products-siblings:', error);
    }

    return siblings;
};

const NostoRecommendationsBlock: FC<Props> = ({
    placementIdentifier,
    pageKey,
    id,
    type,
    customRootClass,
    swiperType = 'product_card',
    isCartPage = false,
    isEmptyCartPage = false,
}) => {
    const { lang } = useParams();
    const [results, setResults] = useState<NostoProductProductResults>();

    const performRecommendationAction = useCallback(
        (session) => {
            switch (type) {
                case 'product':
                    return session.viewProduct(id);
                case 'collection':
                    return session.viewCategory(id);
                case 'landing_page':
                    return session.viewOther();
                case 'stories':
                    return session.viewOther();
                default:
                    throw new Error(`Invalid recommendation type: ${type}`);
            }
        },
        [type, id]
    );

    useLayoutEffect(() => {
        try {
            if (!(window as any).nostojs) return;
            (window as any)?.nostojs((api) => {
                const session = api.defaultSession();
                performRecommendationAction(session)
                    .setPlacements([placementIdentifier])
                    .update()
                    .then((data) => {
                        const recommendations =
                            data.recommendations[placementIdentifier];
                        if (!recommendations) return;
                        const { title, products, result_id } = recommendations;
                        setResults({ title, products, result_id });
                    });
            });
        } catch (e) {
            console.log('Error fetching Nosto recommendations', e);
        }
    }, [pageKey, lang]);

    if (!results) return null;

    const products: ProductCardProps[] = results?.products?.map(
        (item, index) => {
            const {
                product_id: id,
                alternate_image_urls,
                url,
                name,
                price,
                list_price,
                image_url,
                custom_fields,
                price_currency_code,
                skus,
            } = item;
            const pathSegments = new URL(url).pathname.split('/');
            const productHandle = pathSegments[pathSegments.length - 1];
            const siblings = getSiblings(custom_fields);
            const variants: ProductCardVariant[] = skus.map((sku) => {
                return {
                    sku: sku.custom_fields.skuCode,
                    size: sku.custom_fields.Size,
                    availability: sku.available ? 10 : 0,
                };
            });
            const images = findProductCardImagesInSet(
                alternate_image_urls,
                image_url
            );

            const product: ProductCardProduct = {
                id,
                handle: productHandle,
                title: name,
                price: price,
                compareAtPrice: list_price > price ? list_price : undefined,
                image: images[0] || image_url,
                hoverImage: images[1] || alternate_image_urls[0],
                sku: productHandle,
                variants,
                position: index,
                currencyCode: price_currency_code,
            };

            const nostoProductCardAttributes: NostoProductCardAttributes = {
                isNostoProductCard: true,
                result_id: results.result_id,
            };

            return {
                product,
                label: 'RECOMMENDED',
                coloursCount: siblings.length,
                saved: false,
                nostoProductCardAttributes,
                lang: lang as Language,
                Linker: Linker,
            };
        }
    );

    return (
        <>
            {products?.length !== 0 && swiperType === 'product_card' && (
                <SwiperProductNostoStandardVariant
                    products={products}
                    context={results.title || 'Recommended for you'}
                    customRootClass={customRootClass}
                    instanceID={placementIdentifier}
                    lang={lang as Language}
                    nostoResultId={results.result_id}
                />
            )}
            {products?.length !== 0 && swiperType === 'product_rec_card' && (
                <SwiperNostoUpsellCrossSellProductVariant
                    products={products}
                    context={results.title || 'Grab these too'}
                    customRootClass={customRootClass}
                    instanceID={placementIdentifier}
                    lang={lang as Language}
                    nostoResultId={results.result_id}
                    isCartPage={isCartPage}
                    isEmptyCartPage={isEmptyCartPage}
                />
            )}
            {products?.length !== 0 && swiperType === 'flatlay' && (
                <SwiperProductNostoFlatlayVariant
                    products={products}
                    context={results.title || 'Recommended for you'}
                    customRootClass={customRootClass}
                    instanceID={placementIdentifier}
                    lang={lang as Language}
                    nostoResultId={results.result_id}
                />
            )}
            {products?.length !== 0 && swiperType === 'flatlay_alt' && (
                <SwiperProductNostoFlatlayCardAltVariant
                    products={products}
                    context={results.title || 'Recommended for you'}
                    customRootClass={customRootClass}
                    instanceID={placementIdentifier}
                    lang={lang as Language}
                    nostoResultId={results.result_id}
                />
            )}
        </>
    );
};

export default NostoRecommendationsBlock;
