import { ProductVariant, AttributeInput } from 'shopify-storefront-api-typings';
import Cart from '@app/lib/types/Cart';
import { getClient } from '@app/lib/shopify';
import { cartLinesAdd } from '@app/lib/shopify/mutations/cart-lines-add'; // Ensure these paths are correct
import { cartCreate } from '@app/lib/shopify/mutations/cart-create';
import { ShopifyClientContext } from '@app/lib/shopify/client';
import { captureMessage } from '@sentry/nextjs';

interface CartLineItemInput {
    merchandiseId: string;
    quantity: number;
    attributes?: AttributeInput[];
}

export default async (
    clientSettings: ShopifyClientContext,
    cartId: string | null,
    variants: ProductVariant[] | ProductVariant,
    cartCustomAttributes?: AttributeInput[] // These are cart level not line level attributes.
): Promise<Cart | null> => {
    try {
        // Temporary backward compatibility for this function for single variant
        if (!Array.isArray(variants)) {
            variants = [variants];
        }

        const lines: CartLineItemInput[] = variants.map((variant) => ({
            merchandiseId: variant.id,
            quantity: 1,
        }));

        let mutation;
        let variables;

        if (cartId) {
            mutation = cartLinesAdd;
            variables = {
                cartId,
                lines,
                attributes: cartCustomAttributes,
            };
        } else {
            mutation = cartCreate;
            variables = {
                input: {
                    lines,
                    attributes: cartCustomAttributes,
                },
            };
        }

        const cartResponse = await getClient(clientSettings).request.send({
            query: mutation,
            variables: {
                country: clientSettings.shopifyMarketCountryCode,
                ...variables,
                next: { cache: 'nocache' },
            },
        });

        return (
            cartResponse?.cartCreate?.cart ||
            cartResponse?.cartLinesAdd?.cart ||
            null
        );
    } catch (e) {
        let titles: string[] = [];
        if (!Array.isArray(variants)) {
            titles = [variants.product.title];
        } else {
            titles = variants.map((variant) => variant.product.title);
        }
        const message = `Could not add item to checkout: ${titles.join(', ')}`;
        captureMessage(message);
        console.warn(message, e);
    }
    return null;
};
