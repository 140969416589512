export interface AddToCartGTMEvent {
    currencyCode: string;
    name: string;
    id: string;
    price: number;
    brand: string;
    category: string;
    quantity: number;
    variant: string;
    item_id: string;
    item_variant: string;
}

export default function addToCartGTMEvent(event: AddToCartGTMEvent) {
    if ((window as any)?.dataLayer === undefined) {
        return;
    }
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
        event: 'gtm.addCartLineItem',
        ecommerce: {
            currencyCode: event.currencyCode,
            add: {
                products: [event],
            },
        },
    });
}
