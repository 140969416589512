'use client';

import React, { useEffect, useState } from 'react';
import { Left, ProductCard, Right, slugify } from 'mmds';
import { SwiperSlide } from 'swiper/react';
import { Product } from 'shopify-storefront-api-typings';
import ScrollBarSwiper from '../../components/scrollbar-swiper';
import { Language } from '@app/lib/store-context/Context';
import { getShopifyClientSettings } from '@app/lib/shopify/get-client-settings';
import connectShopify from '@app/components/product-card/connect-shopify';
import fetchProductsByHandles from '@app/lib/shopify/methods/fetch-products-by-handles';
import { trimGID } from '@app/lib/shopify/helpers/gid';
import s from './Swiper.module.css';

type SwiperProductNostoStandardVariantProps = {
    products: any;
    context: string;
    customRootClass?: string;
    instanceID?: string;
    lang: Language;
    nostoResultId: string;
};

const SwiperProductNostoStandardVariant = ({
    products,
    context,
    lang,
    instanceID,
    customRootClass,
    nostoResultId,
}: SwiperProductNostoStandardVariantProps) => {
    const [productEdges, setProductEdges] = useState<Product[]>([]);
    const backgroundColor = '#FFFFFF';
    const rootClassName = customRootClass || s.swiperRoot;
    const instance = instanceID || `product_swiper_${slugify(context)}`;

    const trackClick = (productID: string) => {
        typeof window !== 'undefined' &&
            typeof (window as any).nostojs === 'function' &&
            (window as any).nostojs((api) => {
                api.defaultSession()
                    .recordAttribution(
                        'vp',
                        productID.split('/').slice(-1)[0],
                        nostoResultId
                    )
                    .done();
            });
    };

    const clientSettings = getShopifyClientSettings(lang as Language);
    useEffect(() => {
        async function fetchProducts() {
            const productHandles = products.map(
                (product) => product.product.handle
            );
            const productEdges = await fetchProductsByHandles(
                clientSettings,
                productHandles
            );
            setProductEdges(productEdges);
        }

        fetchProducts();
    }, [products]);

    const ConnectedProductCard = connectShopify(ProductCard);
    return (
        <div
            style={{ backgroundColor: backgroundColor }}
            className={rootClassName}
            data-type="recommendation flatlay"
        >
            <div className={s.contextContainer}>
                <div className={s.context}>{context}:</div>
                {products.length > 3 && (
                    <div className={s.iconContainer}>
                        <div
                            className={`swiper-button swiper-icon-prev-${instance}`}
                        >
                            <Left />
                        </div>
                        <div
                            className={`swiper-button swiper-icon-next-${instance}`}
                        >
                            <Right />
                        </div>
                    </div>
                )}
            </div>
            <ScrollBarSwiper
                slidePerViewInMobile={1.99}
                slidePerViewInDesktop={4.35}
                spaceBetweenDesktop={12}
                spaceBetweenMobile={8}
                swiperIconPrevClassName={`swiper-icon-prev-${instance}`}
                swiperIconNextClassName={`swiper-icon-next-${instance}`}
                className={instance}
                key={instance}
                data-testid={instance}
            >
                {productEdges.map((edge, index: number) => {
                    return (
                        <SwiperSlide
                            key={`sku-swiper-slide-${index}`}
                            data-product-id={trimGID(edge.id)}
                            data-product-handle={edge.handle}
                            onClick={() => trackClick(trimGID(edge.id))}
                        >
                            <ConnectedProductCard
                                product={edge}
                                position={index}
                                clientSettings={clientSettings}
                                onClick={() => trackClick(trimGID(edge.id))}
                            />
                        </SwiperSlide>
                    );
                })}
            </ScrollBarSwiper>
        </div>
    );
};

export default SwiperProductNostoStandardVariant;
