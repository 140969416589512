import { gql } from 'graphql-request';
import { CART_FIELDS } from '@app/lib/shopify/queries/fragments/cart-fields';

export const cartLinesAdd = gql`
    mutation cartLinesAdd(
        $country: CountryCode
        $cartId: ID!
        $lines: [CartLineInput!]!
    ) @inContext(country: $country) {
        cartLinesAdd(cartId: $cartId, lines: $lines) {
            userErrors {
                message
                field
            }
            cart {
                ...cartFields
            }
        }
    }
    ${CART_FIELDS}
`;
