'use client';

import React, { useCallback, useRef, useState } from 'react';
import { PhotoVariant, ProductCardProduct } from 'mmds';
import { Product } from 'shopify-storefront-api-typings';
import mapProductEdgeToProductCard from '@app/lib/shopify/methods/map-product-edge-to-product-card';
import useUI from '@app/components/ui/state';
import Linker from '@app/components/linker/linker';
import { useParams } from 'next/navigation';
import { Language } from '@app/lib/store-context/Context';
import { fetchProductCountByParentSKU } from '@app/lib/shopify/methods/fetch-sku-powered-product-count';
import { getAppropriateCollectionHandle } from '@app/[lang]/products/utilities';
import useWishlist from '@app/lib/wishlist/state';

type ProductWithMetafields = Product & {
    parentSKU?: { value: string };
};

type ConnectedProductCardProps = {
    product: ProductCardProduct;
};

export default function connectShopify<P>(
    ProductCard: React.ComponentType<P & ConnectedProductCardProps>
) {
    const ConnectedProductCard = ({
        product,
        photoVariant,
        position,
        clientSettings,
        collectionHandle,
        onClick,
        imageGetter,
        isCartPage,
        isEmptyCartPage,
        ...rest
    }: {
        product: ProductWithMetafields;
        photoVariant?: PhotoVariant;
        position?: number | null;
        coloursCount?: number | null;
        clientSettings?: any;
        collectionHandle?: string;
        imageGetter?: (imageSRCs: string[]) => (string | undefined)[];
        onClick?: () => void;
        isCartPage?: boolean;
        isEmptyCartPage?: boolean;
    }) => {
        const [coloursCount, setColoursCount] = useState<number | null>(null);
        const countCache = useRef({});
        const [hovered, setHovered] = useState<Boolean>(false);
        const { lang } = useParams();
        const { toggleItem, isProductSaved } = useWishlist();
        const { toggleUI } = useUI();
        // const isWishlistFeatureEnabled = getFeatureFlag('wishlist');
        const isWishlistFeatureEnabled = false;

        const handleMouseEnter = useCallback(async () => {
            let parentSKU = product.parentSKU?.value || null;
            if (!parentSKU) {
                return;
            }
            setHovered(true);
            if (countCache.current[parentSKU]) {
                setColoursCount(countCache.current[parentSKU]);
            } else {
                const siblingsCollectionHandle =
                    getAppropriateCollectionHandle(product);
                const count = await fetchProductCountByParentSKU(
                    clientSettings,
                    siblingsCollectionHandle,
                    parentSKU
                );
                countCache.current[parentSKU] = count;
                setColoursCount(count);
            }
        }, [product]);

        const handleToggleSaved = useCallback(
            (variantSKU: string, handle: string) => {
                toggleItem({
                    sku: variantSKU,
                    handle,
                });
                if (isProductSaved(handle as string)) toggleUI('wishlist');
            },
            [product]
        );

        const handleMouseLeave = useCallback(() => {
            setHovered(false);
        }, []);

        const mappedProduct = mapProductEdgeToProductCard(
            {
                node: product,
                cursor: '',
            },
            lang as Language,
            imageGetter
        );
        mappedProduct.saved = isProductSaved(mappedProduct.product.handle);
        if (isWishlistFeatureEnabled) {
            mappedProduct.toggleSaved = handleToggleSaved;
        }

        return (
            <ProductCard
                {...mappedProduct}
                photoVariant={photoVariant}
                position={position}
                lang={lang}
                Linker={Linker}
                coloursCount={coloursCount}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                hovered={hovered}
                onClick={onClick}
                isCartPage={isCartPage}
                isEmptyCartPage={isEmptyCartPage}
                {...(rest as P)}
            />
        );
    };

    ConnectedProductCard.displayName = `connectedProductCard`;

    return ConnectedProductCard;
}
