declare const window: {
    fbq: any;
} & Window;

export interface AddToCartPixelEvent {
    content_name: string;
    content_ids: string[];
    content_type: string;
    content_category: string;
    value: number;
    currency: string;
    quantity: number;
}

export default function addToCartPixelEvent(event: AddToCartPixelEvent) {
    if (typeof window.fbq !== 'undefined') {
        window.fbq('track', 'AddToCart', {
            content_name: event.content_name,
            content_ids: event.content_ids,
            content_type: event.content_type,
            content_category: event.content_category,
            value: event.value,
            currency: event.currency,
            quantity: event.quantity,
        });
    } else {
        console.warn(
            'Facebook Pixel not initialized before using addToCartPixelEvent'
        );
    }
}
