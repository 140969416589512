import { Language } from '@app/lib/store-context/Context';

export default (language: Language) => {
    if (typeof document === 'undefined' || !language) return null;
    const cookieIdentifier = `shopify_cart_id_${language.toLowerCase()}`;
    const cookie = document.cookie
        .split('; ')
        .find((cookie) => cookie.startsWith(cookieIdentifier));
    if (!cookie) {
        return null;
    }
    return cookie.replace(`${cookie.split('=')[0]}=`, '');
};
