import Link from 'next/link';
import localisePath from '@app/lib/helpers/localise-path';
import { Language } from '@app/lib/store-context/Context';

export interface LinkerProps {
    href: string | null;
    lang: Language;
    title: string;
    className?: string;
    target?: string;
    prefetch?: boolean;
    passthrough?: boolean;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    children: React.ReactNode;
}

function Linker({
    href,
    lang,
    title,
    className,
    target = '_self',
    prefetch = false,
    passthrough = false,
    onClick,
    children,
}: LinkerProps) {
    if (passthrough || !href) {
        return children;
    }
    const isExternal = href.startsWith('http');
    return (
        <Link
            href={isExternal ? href : localisePath(href, lang)}
            prefetch={prefetch}
            passHref={passthrough}
            className={className}
            onClick={onClick}
            title={title}
        >
            {children}
        </Link>
    );
}

export default Linker;
