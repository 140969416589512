import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { ReadonlyURLSearchParams } from 'next/navigation';
import { Language } from '@app/lib/store-context/Context';
import getOptionForLang from '@app/lib/store-context/get-option-for-lang';

export interface LoopState {
    lang: Language | null;
    currency: string;
    base: number;
    availableCredit: number;
    checkoutURL: string | null;
    domain: string | null;
    checkoutToken: string | null;
    selectedVariantIDs: string[];
    isCreditShoppingActive: boolean;
    loadFromSearchParams: (
        searchParams: ReadonlyURLSearchParams,
        lang: Language
    ) => void;
    setVariantIDs: (variantIDs: string[]) => void;
    createOrUpdateLoopCart: (variantIDs: string[]) => void;
    completeReturn: () => void;
}

const LOOP_API_BASE_URL = 'https://api.loopreturns.com/api/v1/cart';

const initialState = {
    lang: null,
    currency: 'AUD',
    base: 0,
    availableCredit: 0,
    checkoutURL: null,
    domain: null,
    checkoutToken: null,
    selectedVariantIDs: [],
    isCreditShoppingActive: false,
};

export const useLoopReturns = create<LoopState>()(
    persist(
        (set, get) => ({
            ...initialState,

            setVariantIDs: async (variantIDs: string[]) => {
                if (variantIDs.length) {
                    get().createOrUpdateLoopCart(variantIDs);
                }
            },

            createOrUpdateLoopCart: async (variantIDs: string[]) => {
                // Update or new based on presence of token.
                if (variantIDs === get().selectedVariantIDs) return;
                if (!get().lang) return;
                const API_URL = get().checkoutToken
                    ? `${LOOP_API_BASE_URL}/${get().checkoutToken}`
                    : LOOP_API_BASE_URL;
                const apiKey = getOptionForLang(
                    'loopApiKey',
                    get().lang as Language
                );
                if (!apiKey) return;
                const response = await fetch(API_URL, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'X-Authorization': apiKey,
                    },
                    body: JSON.stringify({ cart: variantIDs }),
                });

                const loopReturnsResponseData = await response.json();
                if (loopReturnsResponseData.token) {
                    const checkoutURL = `https://${get().domain}/#/cart/v2/${
                        loopReturnsResponseData.token
                    }`;

                    set({
                        checkoutURL,
                        checkoutToken: loopReturnsResponseData.token,
                    });
                }
            },

            loadFromSearchParams: (searchParams, lang: Language) => {
                const creditTotal =
                    parseFloat(searchParams.get('loop_base') as string) ?? 0;
                const creditTotalDollarAmount = creditTotal / 100;

                set({
                    currency: searchParams.get('loop_currency') || 'AUD',
                    base:
                        parseInt(searchParams.get('loop_base') as string) || 0,
                    domain: (searchParams.get('loop_domain') as string) || null,
                    availableCredit: creditTotalDollarAmount || 0,
                    checkoutToken:
                        searchParams.get('loop_customer_name') || null,
                    isCreditShoppingActive: !!searchParams.get('loop_total'),
                    lang,
                });
            },

            completeReturn: async () => {
                set(initialState);
            },
        }),
        {
            name: 'loop:cache',
            storage: createJSONStorage(() => localStorage),
        }
    )
);

export default useLoopReturns;
