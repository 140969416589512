import s from './flatlay-alt-card.module.css';
import { convertPriceToNumber } from 'mmds/src/utilities/convert-price-to-number';
import { Language } from '@app/lib/store-context/Context';
import TruncateTitle from '@app/lib/helpers/truncate-title';
import cn from 'clsx';
import { StaticImage } from 'mmds';

type FlatlayAltCardProps = {
    product: any;
    lang: Language;
};

const FlatlayAltCard = ({ product, lang }: FlatlayAltCardProps) => {
    const { title, price, compareAtPrice, currencyCode, image } = product;

    let priceAsNumber =
        typeof price !== 'number' ? convertPriceToNumber(price) : price;
    let compareAtPriceAsNumber =
        typeof compareAtPrice !== 'number'
            ? convertPriceToNumber(compareAtPrice)
            : compareAtPrice;

    const showCompareAtPrice =
        compareAtPrice && compareAtPriceAsNumber > priceAsNumber;
    const priceFormatter = new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: currencyCode,
    });

    return (
        <div className={s.root}>
            <StaticImage
                desktopImage={image}
                mobileImage={image}
                desktopHeight={214}
                desktopWidth={160}
                mobileHeight={187}
                mobileWidth={140}
                altDescription={title}
                fullWidth={true}
            />
            <div className={s.blankHeight}>
                <div className={s.title} />
                <div className={s.priceContainer}>
                    <div className={s.price} />
                    <div className={s.compareAtPrice} />
                </div>
            </div>
            <div className={s.summary}>
                <div
                    className={cn(
                        'mmds-component-two-detail',
                        s.title,
                        s.desktopOnly
                    )}
                >
                    <TruncateTitle text={title} maxLength={33} />
                </div>
                <div
                    className={cn(
                        'mmds-component-two-detail',
                        s.title,
                        s.mobileOnly
                    )}
                >
                    <TruncateTitle text={title} maxLength={23} />
                </div>
                <div
                    className={cn(
                        'mmds-component-two-detail',
                        s.priceContainer
                    )}
                >
                    <div className={s.compareAtPrice}>
                        {showCompareAtPrice && (
                            <span>
                                {priceFormatter.format(compareAtPriceAsNumber)}{' '}
                                {currencyCode}
                            </span>
                        )}
                    </div>
                    <div className={s.price}>
                        {priceFormatter.format(priceAsNumber)} {currencyCode}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlatlayAltCard;
