'use client';

import React, { useEffect, useState } from 'react';
import s from '@app/components/swiper/Swiper.module.css';
import { Left, Right, slugify } from 'mmds';
import { getShopifyClientSettings } from '@app/lib/shopify/get-client-settings';
import { Language } from '@app/lib/store-context/Context';
import ScrollBarSwiper from '@app/components/scrollbar-swiper';
import { SwiperSlide } from 'swiper/react';
import { trimGID } from '@app/lib/shopify/helpers/gid';
import connectShopify from '@app/components/product-card/connect-shopify';
import ProductRecCard from '@app/components/product-rec-card/product-rec-card';
import fetchProductsByHandles from '@app/lib/shopify/methods/fetch-products-by-handles';
import { Product } from 'shopify-storefront-api-typings';
import cn from 'clsx';
import { SimplifiedBuyContextProvider } from '@app/[lang]/products/context-simplified';

type SwiperNostoUpsellCrossSellProductVariantProps = {
    products: any;
    context: string;
    customRootClass?: string;
    instanceID?: string;
    lang: Language;
    nostoResultId: string;
    isCartPage: boolean;
    isEmptyCartPage: boolean;
};

const SwiperNostoUpsellCrossSellProductVariant = ({
    products,
    context,
    lang,
    instanceID,
    customRootClass,
    nostoResultId,
    isCartPage,
    isEmptyCartPage,
}: SwiperNostoUpsellCrossSellProductVariantProps) => {
    const [productEdges, setProductEdges] = useState<Product[]>([]);
    const rootClassName =
        customRootClass ||
        cn(
            isCartPage
                ? s.upSellCrossCartPageSwiperRoot
                : s.upSellCrossSellSwiperRoot,
            'productNostoRecCard'
        );
    const backgroundColor = isCartPage ? '#FFFFFF' : '#F7F7F7';
    const instance =
        instanceID ||
        `product_nosto_upsell_cross_sell_swiper_${slugify(context)}`;

    const trackClick = (productID: string) => {
        typeof window !== 'undefined' &&
            typeof (window as any).nostojs === 'function' &&
            (window as any).nostojs((api) => {
                api.defaultSession()
                    .recordAttribution(
                        'vp',
                        productID.split('/').slice(-1)[0],
                        nostoResultId
                    )
                    .done();
            });
    };

    const clientSettings = getShopifyClientSettings(lang as Language);
    useEffect(() => {
        async function fetchProducts() {
            const productHandles = products.map(
                (product) => product.product.handle
            );

            const productEdges = await fetchProductsByHandles(
                clientSettings,
                productHandles
            );
            setProductEdges(productEdges);
        }

        fetchProducts();
    }, [products]);

    const ConnectedProductCard = connectShopify(ProductRecCard);

    const slidePerViewDesktopValue = (
        isCartPage: boolean,
        isEmptyCartPage: boolean
    ) => {
        if (isCartPage) {
            return 2.2;
        } else if (isEmptyCartPage) {
            return 3.9;
        } else {
            return 1.56;
        }
    };

    return (
        <div
            style={{ backgroundColor: backgroundColor }}
            className={rootClassName}
            data-type="nosto recommendation for upsell cross sell products"
        >
            <div className={s.contextContainer}>
                <div className={s.context}>{context}:</div>
                {products.length > 4 && (
                    <div className={s.iconContainer}>
                        <div
                            className={`swiper-button swiper-icon-prev-${instance}`}
                        >
                            <Left />
                        </div>
                        <div
                            className={`swiper-button swiper-icon-next-${instance}`}
                        >
                            <Right />
                        </div>
                    </div>
                )}
            </div>
            <ScrollBarSwiper
                slidePerViewInMobile={1.48}
                slidePerViewInDesktop={slidePerViewDesktopValue(
                    isCartPage,
                    isEmptyCartPage
                )}
                spaceBetweenDesktop={12}
                spaceBetweenMobile={8}
                swiperIconPrevClassName={`swiper-icon-prev-${instance}`}
                swiperIconNextClassName={`swiper-icon-next-${instance}`}
                className={instance}
                key={instance}
                data-testid={instance}
            >
                {productEdges.map((edge, index: number) => {
                    return (
                        <SwiperSlide
                            key={`nosto-upsell-cross-sell-swiper-slide-${index}`}
                            data-product-id={trimGID(edge.id)}
                            data-product-handle={edge.handle}
                            onClick={() => trackClick(trimGID(edge.id))}
                        >
                            <SimplifiedBuyContextProvider
                                product={edge}
                                inDrawer
                            >
                                <ConnectedProductCard
                                    product={edge}
                                    position={index}
                                    clientSettings={clientSettings}
                                    onClick={() => trackClick(trimGID(edge.id))}
                                    isCartPage={isCartPage}
                                    isEmptyCartPage={isEmptyCartPage}
                                />
                            </SimplifiedBuyContextProvider>
                        </SwiperSlide>
                    );
                })}
            </ScrollBarSwiper>
        </div>
    );
};

export default SwiperNostoUpsellCrossSellProductVariant;
