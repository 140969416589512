'use client';

import React, { useEffect, useState } from 'react';
import throttledWidthObserver from '../../../app/lib/observers/throttled-width-observer';
import {
    FreeMode,
    Keyboard,
    Mousewheel,
    Navigation,
    Scrollbar,
} from 'swiper/modules';
import { Swiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import './scrollbar-swiper-styles.css';

interface ScrollBarSwiperProps {
    children: any;
    className: string;
    slidePerViewInMobile?: number;
    slidePerViewInDesktop?: number;
    spaceBetweenDesktop?: number;
    spaceBetweenMobile?: number;
    swiperIconNextClassName?: string;
    swiperIconPrevClassName?: string;
    hasScrollbar?: boolean;
    direction?: 'horizontal' | 'vertical';
    sidebar?: boolean;
}

export const ScrollBarSwiper = ({
    children,
    className,
    slidePerViewInMobile,
    slidePerViewInDesktop,
    spaceBetweenDesktop,
    spaceBetweenMobile,
    swiperIconNextClassName,
    swiperIconPrevClassName,
    hasScrollbar = true,
    direction = 'horizontal',
}: ScrollBarSwiperProps) => {
    const clientWidth =
        typeof document !== 'undefined' ? document.body.clientWidth : 1280;
    const [windowSize, setWindowSize] = useState<number>(clientWidth);

    useEffect(() => {
        if ((document as any) === 'undefined') return;
        const observer = throttledWidthObserver(
            document.body,
            setWindowSize,
            100
        );
        observer.observe(document.body);
        return () => observer.unobserve(document.body);
    }, []);

    return (
        <>
            <div className="swiperContainer">
                <Swiper
                    direction={direction}
                    navigation={{
                        nextEl: `.${swiperIconNextClassName}`,
                        prevEl: `.${swiperIconPrevClassName}`,
                        disabledClass: 'swiper-icon-disabled',
                    }}
                    observer={true}
                    freeMode={true}
                    // cssMode={windowSize < 768}
                    scrollbar={hasScrollbar}
                    mousewheel={{ forceToAxis: true }}
                    keyboard={true}
                    modules={[
                        Navigation,
                        Scrollbar,
                        FreeMode,
                        Keyboard,
                        Mousewheel,
                    ]}
                    className={`${className} scrollbar-swiper`}
                    slidesOffsetAfter={windowSize > 991 ? 72 : 24}
                    slidesOffsetBefore={windowSize > 991 ? 72 : 24}
                    breakpoints={{
                        320: {
                            slidesPerView: slidePerViewInMobile
                                ? slidePerViewInMobile
                                : 1.12,
                            spaceBetween: spaceBetweenMobile,
                        },
                        768: {
                            slidesPerView: slidePerViewInMobile
                                ? slidePerViewInMobile
                                : 2.1,
                            spaceBetween: spaceBetweenMobile,
                        },
                        1024: {
                            slidesPerView: slidePerViewInDesktop
                                ? slidePerViewInDesktop
                                : 2.9,
                            spaceBetween: spaceBetweenDesktop,
                        },
                    }}
                >
                    {children}
                </Swiper>
            </div>
        </>
    );
};

export default ScrollBarSwiper;
