import productCountByMetafield from '../queries/product-count-by-metafield';
import { getClient } from '@app/lib/shopify';
import { createSiblingFilters } from '../helpers/create-sibling-filters';

export async function fetchProductCountByParentSKU(
    clientSettings,
    collectionHandle,
    parentSKU
) {
    const filters = createSiblingFilters(parentSKU);
    try {
        const response = await getClient(clientSettings).request.send({
            query: productCountByMetafield,
            variables: {
                country: clientSettings.shopifyMarketCountryCode,
                collectionHandle,
                filters,
            },
        });

        const productCount = response.collection.products.edges.length;
        return productCount;
    } catch (error) {
        console.error('Failed to fetch product count', error);
        return 0;
    }
}
