import { create } from 'zustand';
import Cart from '@app/lib/types/Cart';
import { persist, createJSONStorage } from 'zustand/middleware';

interface CartNotice {
    id: string;
    heading?: string;
    message: string;
    link?: string;
    notAvailable?: boolean;
}

interface CartState {
    cart: Cart | null;
    cartItemsCount: number;
    initialised: boolean;
    notices: CartNotice[];
    setCart(cart: Cart): void;
    addNotice(notice: CartNotice): void;
    clearCart(): void;
}

const useCart = create<CartState>()(
    persist(
        (set, get) => ({
            cart: null,
            cartItemsCount: 0,
            initialised: false,
            notices: [],
            clearCart: () =>
                set({ cart: null, cartItemsCount: 0, notices: [] }),
            setCart: (cart: Cart) =>
                set(() => {
                    let cartItemsCount = 0;
                    if (cart.lines) {
                        cartItemsCount = cart.lines.edges.reduce(
                            (acc, edge) => acc + edge.node.quantity,
                            0
                        );
                    }
                    return { cart, cartItemsCount, initialised: true };
                }),
            addNotice: (notice: any) => {
                set((state) => {
                    return {
                        ...state,
                        notices: [...state.notices, notice],
                    };
                });
            },
        }),
        {
            name: 'cart:cache',
            storage: createJSONStorage(() => localStorage), // This could also be indexedDB or sessionStorage or....
            partialize: (state) => ({
                notices: state.notices,
            }),
        }
    )
);

export default useCart;
