// Limit the title up until default = 18 words & added dots for more info.
export default ({ text, maxLength = 18 }) => {
    const truncate = (str: string, num: number) => {
        if (str.length >= num) {
            return `${str.slice(0, num)}...`;
        }
        return str;
    };

    return truncate(text, maxLength);
};
