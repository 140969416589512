export default (imageSRCs: string[], defaultImageSRC?: string) => {
    const flatlayImage = imageSRCs.find((url: string | string[]) =>
        url.includes('_LP_FLATLAY')
    );

    const flatlayHoverImage = imageSRCs.find((url: string | string[]) =>
        url.includes('_PDP_01')
    );

    const flatlayImageFallback = imageSRCs.find((url: string | string[]) =>
        url.includes('_PRODUCT_CARD_HERO')
    );

    const flatlayHoverImageFallback = imageSRCs.find((url: string | string[]) =>
        url.includes('_PRODUCT_CARD_ALT')
    );

    let mainImage = flatlayImage || flatlayImageFallback;
    const hoverImage = flatlayHoverImage || flatlayHoverImageFallback;

    if (mainImage && defaultImageSRC?.includes('_LP_FLATLAY'))
        mainImage = defaultImageSRC;

    return [mainImage, hoverImage];
};
