import { gql } from 'graphql-request';

export const CART_FIELDS = gql`
    fragment cartFields on Cart {
        id
        checkoutUrl
        createdAt
        updatedAt
        totalQuantity
        buyerIdentity {
            email
        }
        lines(first: 200) {
            edges {
                node {
                    id
                    quantity
                    merchandise {
                        ... on ProductVariant {
                            id
                            title
                            price {
                                amount
                                currencyCode
                            }
                            compareAtPrice {
                                amount
                                currencyCode
                            }
                            availableForSale
                            product {
                                id
                                title
                                handle
                                descriptionHtml
                                productType
                            }
                            image {
                                transformedSrc
                            }
                            sku
                            selectedOptions {
                                name
                                value
                            }
                        }
                    }
                    attributes {
                        key
                        value
                    }
                    cost {
                        subtotalAmount {
                            amount
                            currencyCode
                        }
                        totalAmount {
                            amount
                            currencyCode
                        }
                    }
                    discountAllocations {
                        discountedAmount {
                            amount
                            currencyCode
                        }
                    }
                }
            }
        }
        attributes {
            key
            value
        }
        cost {
            checkoutChargeAmount {
                amount
                currencyCode
            }
            subtotalAmount {
                amount
                currencyCode
            }
            subtotalAmountEstimated
            totalAmount {
                amount
                currencyCode
            }
            totalAmountEstimated
            totalDutyAmount {
                amount
                currencyCode
            }
            totalDutyAmountEstimated
            totalTaxAmount {
                amount
                currencyCode
            }
            totalTaxAmountEstimated
        }
        discountCodes {
            code
            applicable
        }
    }
`;
