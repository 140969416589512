import DefaultCollectionFilters from '../filters/default-collection-filters';

export const createSiblingFilters = (parentSKU: string) => [
    ...DefaultCollectionFilters,
    {
        productMetafield: {
            namespace: 'custom',
            key: 'parent_sku',
            value: parentSKU,
        },
    },
];
