import { ImageEdge, ProductEdge } from 'shopify-storefront-api-typings';
import { ProductCardProps, ProductCardVariant } from 'mmds';
import findProductCardImagesInSet from '@app/lib/shopify/helpers/find-product-card-images-in-set';
import { Language } from '@app/lib/store-context/Context';

const extractVideoParams = (mediaEdges: any) => {
    if (!mediaEdges || !mediaEdges[0]?.node?.id) return null;
    const videoNode = mediaEdges[0].node;

    const url = videoNode?.sources[2]?.url; // 720p version
    const poster = videoNode?.previewImage?.transformedSrc;

    return {
        url,
        poster,
    };
};

export default function (
    edge: ProductEdge,
    lang: Language,
    imageGetter: (
        imageSRCs: string[]
    ) => (string | undefined)[] = findProductCardImagesInSet
): ProductCardProps {
    const { node } = edge;
    const { handle, title, variants, tags } = node;

    // Shopify typings doesnt know about these property.
    // @ts-ignore
    const { isNewArrival, videoURL } = node;

    const mappedVariants = variants.edges.map((edge: any) => {
        const {
            sku,
            quantityAvailable,
            priceV2,
            compareAtPriceV2,
            selectedOptions,
        } = edge.node;

        let colour = selectedOptions.find((option) => option.name === 'Color');

        let size = selectedOptions.find(
            (option: any) => option.name === 'Size'
        );

        if (!size) {
            size = {
                value: 'One Size',
            };
        }

        if (!colour) {
            colour = {
                value: '',
            };
        }

        return {
            size: size.value,
            color: colour.value,
            sku: sku,
            availability: quantityAvailable,
            price: priceV2.amount,
            currencyCode: priceV2.currencyCode,
            compareAtPrice: compareAtPriceV2?.amount,
        };
    });

    let defaultVariant = mappedVariants[0];
    const availableVariants = mappedVariants.filter(
        (variant: ProductCardVariant) => variant.availability > 0
    );

    if (availableVariants.length) {
        defaultVariant = availableVariants[0];
    }

    const imageSRCs = node.images.edges.map((image: ImageEdge) => {
        return image.node.transformedSrc;
    });
    const images = imageGetter(imageSRCs);

    // Product card badges can be either the automated "New" badge (from Shopify metafield + Flow).
    // or a custom badge by adding the tag badge:{text} to a product.
    let label: string | null = null;
    if (isNewArrival?.value === 'true') {
        label = 'New';
    }

    const labelTag = tags.find((tag: string) => tag.includes('badge:'));
    if (labelTag && !label) label = labelTag.replace('badge:', '');

    const { price, compareAtPrice, currencyCode, sku } = defaultVariant;

    let videoParams = extractVideoParams(node.media.edges);
    if (!videoParams?.url && videoURL?.value) {
        videoParams = {
            url: videoURL?.value,
            poster: images[0],
        };
    }

    return {
        product: {
            id: node.id,
            sku,
            handle,
            title,
            currencyCode,
            price,
            compareAtPrice,
            image: images[0],
            hoverImage: images[1],
            video: videoParams || undefined,
            variants: mappedVariants,
        },
        variant: node.variants[1],
        saved: false,
        label,
        lang,
    };
}
