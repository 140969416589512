'use client';

import { SwiperSlide } from 'swiper/react';
import ScrollBarSwiper from '../../components/scrollbar-swiper';
import { Left, Right, slugify, StaticImage } from 'mmds';
import s from './Swiper.module.css';
import { Language } from '@app/lib/store-context/Context';
import Linker from '@app/components/linker/linker';

type SwiperProductNostoFlatlayProps = {
    products: any;
    context: string;
    customRootClass?: string;
    instanceID?: string;
    lang: Language;
    nostoResultId: string;
};

const SwiperProductNostoFlatlay = ({
    products,
    context,
    lang,
    instanceID,
    customRootClass,
    nostoResultId,
}: SwiperProductNostoFlatlayProps) => {
    const backgroundColor = '#FFFFFF';
    const rootClassName = customRootClass || s.swiperRoot;
    const instance = instanceID || `product_swiper_${slugify(context)}`;

    const trackClick = (productID: string) => {
        typeof window !== 'undefined' &&
            typeof (window as any).nostojs === 'function' &&
            (window as any).nostojs((api) => {
                api.defaultSession()
                    .recordAttribution(
                        'vp',
                        productID.split('/').slice(-1)[0],
                        nostoResultId
                    )
                    .done();
            });
    };

    return (
        <div
            style={{ backgroundColor: backgroundColor }}
            className={rootClassName}
            data-type="recommendation flatlay"
        >
            <div className={s.contextContainer}>
                <div className={s.context}>{context}:</div>
                {products.length > 3 && (
                    <div className={s.iconContainer}>
                        <div
                            className={`swiper-button swiper-icon-prev-${instance}`}
                        >
                            <Left />
                        </div>
                        <div
                            className={`swiper-button swiper-icon-next-${instance}`}
                        >
                            <Right />
                        </div>
                    </div>
                )}
            </div>
            <ScrollBarSwiper
                slidePerViewInMobile={2.9}
                slidePerViewInDesktop={9.4}
                spaceBetweenDesktop={0}
                spaceBetweenMobile={0}
                swiperIconPrevClassName={`swiper-icon-prev-${instance}`}
                swiperIconNextClassName={`swiper-icon-next-${instance}`}
                className={instance}
                key={instance}
                data-testid={instance}
            >
                {products?.map((item: any, index: number) => {
                    const { handle, title, image, id } = item.product;
                    return (
                        <SwiperSlide key={index}>
                            <div className={backgroundColor ? '' : s.image}>
                                <Linker
                                    passthrough={!handle}
                                    href={`/products/${handle}`}
                                    title={`Go to product page: ${title}`}
                                    lang={lang}
                                    onClick={() => trackClick(id)}
                                >
                                    <StaticImage
                                        desktopImage={image}
                                        mobileImage={image}
                                        altDescription={title}
                                        fullWidth={true}
                                    />
                                </Linker>
                            </div>
                        </SwiperSlide>
                    );
                })}
            </ScrollBarSwiper>
        </div>
    );
};

export default SwiperProductNostoFlatlay;
